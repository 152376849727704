import { Skeleton } from "@/Components/ui/skeleton";
import { cn } from "@/lib/utils";
import loading from '@assets/process-animate.svg';

export default function Loader({ className, textClass, text = "", iconHeight = 80, iconWidth = 80 }) {
    return <Skeleton className={cn("h-96 text-muted bg-muted-foreground col-span-1 flex flex-col gap-2 justify-center items-center", className)}>
        <img className="max-w-[250px]" src={loading} width={iconWidth} height={iconHeight} />
        {!!text && <p className={cn("mt-2", textClass)}>{text}</p>}
    </Skeleton>
}
